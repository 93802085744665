import {
	SetStateAction,
	useCallback,
	useEffect,
	useState,
	KeyboardEvent,
	CSSProperties,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import auth0 from 'auth0-js';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Grid, Typography, Theme } from '@mui/material';
import TableclothLogo from '@/assets/icons/TableclothLogo.svg';
import { TextField, Button } from '@/components';
import useMixpanel from '@/hooks/useMixpanel';
import useNotification from '@/hooks/useNotification';
import useSafeReplace from '@/hooks/useSafeReplace';
import {
	LOG_IN_PENDING,
	LOG_IN_ERROR,
	SAVE_TOKEN,
	LOG_IN_SUCCESS,
	RESET_LOGOUT,
} from '@/store/actions';
import { RootState } from '@/store/slices';
import { useUserService } from '@/store/services/user.service';
import { AUTH0_AUDIENCE, AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '@/utils/constants';
import { getFullUrl } from '@/utils/helpers';

const styles = {
	container: {
		height: '100vh',
		overflow: 'hidden',
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	text: {
		fontSize: '24px',
		fontWeight: '600',
		fontFamily: 'Poppins',
		lineHeight: '28.8px',
		color: 'text.primary',
	},
	links: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	link: {
		color: (theme: Theme) => theme.palette.primary.main,
		cursor: 'pointer',
		fontSize: '12px',
		marginBottom: '4px',
	},
};

function Login() {
	const router = useRouter();
	const dispatch = useDispatch();
	const { initializeMixpanel, track } = useMixpanel();
	const [email, setEmail] = useState('');
	const { safeReplace } = useSafeReplace();
	const [password, setPassword] = useState('');
	const { setDefaultAccount } = useUserService();

	const auth = useSelector((state: RootState) => state.auth);
	const { loading, error } = auth;
	const { sendNotification } = useNotification();

	const redirectUser = useCallback(async () => {
		await setDefaultAccount();
		const redirectUrl = localStorage.getItem('redirectUrl') || '/';
		location.href = redirectUrl.toString();
	}, [setDefaultAccount]);

	useEffect(() => {
		if (error && !loading) {
			sendNotification({ msg: error.message, variant: 'error' });
		}
	}, [error, loading, sendNotification]);

	useEffect(() => {
		if (auth.logout) {
			dispatch(RESET_LOGOUT());
		}
	}, [dispatch, auth.logout]);

	useEffect(() => {
		//This is the param that is being returned from the auth0 callback.
		//We are using this to show the user the success message after they have changed their password.
		if (router.asPath.includes('success=true') && !auth.token) {
			sendNotification({
				msg: 'You can now login with your updated password.',
				variant: 'success',
			});
		}
	}, [router.asPath, sendNotification, auth.token]);

	useEffect(() => {
		if (auth.token && router.pathname.includes('/login')) {
			redirectUser();
		}
	}, [auth.token, redirectUser, router.pathname]);

	const handleLogin = () => {
		dispatch(LOG_IN_PENDING());
		const webAuth = new auth0.WebAuth({
			domain: AUTH0_DOMAIN,
			clientID: AUTH0_CLIENT_ID,
			redirectUri: getFullUrl('/'),
		});

		return webAuth.client.login(
			{
				username: email,
				password,
				scope: 'openid profile email',
				realm: 'Username-Password-Authentication',
				audience: AUTH0_AUDIENCE,
			},
			(error, authInfo) => {
				if (error) {
					dispatch(LOG_IN_ERROR({ error: error.description }));
				} else {
					dispatch(LOG_IN_SUCCESS());
					dispatch(SAVE_TOKEN({ token: authInfo.accessToken }));
					initializeMixpanel(email);
					track('User Logged In', { distinct_id: email });
				}
			},
		);
	};

	if (auth.token && router.pathname.includes('/login')) {
		return;
	}

	const handleForgotPassword = () => {
		safeReplace('/forgot-password');
	};

	return (
		<Grid container sx={styles.container}>
			<Grid item flex={{ xs: '1', sm: '2', md: '3' }} width="100%" position="relative">
				<video
					src="https://cdn.filestackcontent.com/LTfDyVk8RWkwfxqfo5eG"
					autoPlay
					loop
					muted
					style={styles.video as CSSProperties}
				></video>
			</Grid>
			<Grid
				item
				container
				flex="1"
				alignContent="flex-start"
				px={{ xs: 2, sm: 4, md: 8 }}
				pt="188px"
				gap={5}
			>
				<Image priority src={TableclothLogo} alt="" height={75} />
				<Grid item container direction="column" gap={2}>
					<Grid item>
						<Typography sx={styles.text}>Sign In</Typography>
					</Grid>
					<Grid item>
						<TextField
							name="email"
							label="Email"
							value={email}
							onChange={(e: { target: { value: SetStateAction<string> } }) =>
								setEmail(e.target.value)
							}
						/>
					</Grid>
					<Grid item>
						<TextField
							name="password"
							label="Password"
							value={password}
							onChange={(e: { target: { value: SetStateAction<string> } }) => {
								setPassword(e.target.value);
							}}
							type="password"
							InputProps={{
								onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === 'Enter') {
										e.preventDefault();
										handleLogin();
									}
								},
							}}
						/>
					</Grid>
					<Grid item>
						<Button text="log in" fullWidth onClick={handleLogin} />
					</Grid>
					<Grid container item sx={styles.links}>
						<Typography sx={styles.link} onClick={handleForgotPassword}>
							Forgot Password?
						</Typography>
						<Link
							href="https://contact.tablecloth.io/help-logging-in-to-tablecloth"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Typography sx={styles.link}>Click here for login help</Typography>
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

Login.displayName = 'Login';

export default Login;
